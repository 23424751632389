*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --text-color: #616a71; 
  --bg-color: #dadfe3;
  --cover-color: #f8f9fb;
}

body {
  /*font-family: Copperplate, "Copperplate Gothic Light";*/
  font-family: 'Open Sans', sans-serif;
  background-color: var(--bg-color);
  overflow-x: hidden;
  height: 100%;
  margin: 0;
}

.main-wrapper {
  position: relative;
}

.main-cover {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 5%;
}

.main-title span {
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 1.2;
  color: var(--text-color);
  letter-spacing: 2px;
}

.navbar {
  background-color: none;
  color: var(--text-color);
  position: relative;
  z-index: 1;
  padding: 10px 0;
  transition: top 0.3s;
  top: -100px;
  height: 55px;
}

.navbar.sticky {
  background-color: var(--cover-color);
  position: fixed;
  top: 0;
  width: 100%;
  animation: slide-down 0.5s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
}

@keyframes slide-down {
  from {
    top: -50px;
  }

  to {
    top: 0;
  }
}

.nav-link {
  opacity: 0;
  transform: translateY(20px);
  margin: 10px;
  transition: opacity 0.5s, transform 0.5s;
  font-size: 1.5vw;
  margin-left: 2%;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-bottom: 1vw;
}

.nav-link.animation-played {
  animation: none;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.nav-link:not(.animation-played):nth-child(1) {
  animation: fadeIn 1s ease forwards 0.5s;
}

.nav-link:not(.animation-played):nth-child(2) {
  animation: fadeIn 1s ease forwards 1s;
}

.nav-link:not(.animation-played):nth-child(3) {
  animation: fadeIn 1s ease forwards 1.5s;
}

.nav-link:not(.animation-played):nth-child(4) {
  animation: fadeIn 1s ease forwards 2s;
}

.nav-link:not(.animation-played):nth-child(5) {
  animation: fadeIn 1s ease forwards 2.5s;
}

.nav-link:not(.animation-played):nth-child(6) {
  animation: fadeIn 1s ease forwards 3s;
}

.nav-link:not(.animation-played):nth-child(7) {
  animation: fadeIn 1s ease forwards 3.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link:before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: var(--text-color);
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}

.nav-link:hover:before {
  width: 100%;
}

.nav-a {
  display: none;
}

.nav-link.sticky {
  align-items: center;
}

.nav-link.sticky:hover {
  font-weight: bold;
}

.nav-link.active {
  font-weight: bold;
}


/*********************************************/
.content {
  margin-top: -55px;
}

.title-div {
  background-color: var(--text-color);
  align-items: center;
  position: relative;
}

.title-div p {
  color: var(--cover-color);
  padding: 10px 10px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.title-div p span {
  font-size: 30px;
  margin-right: 10px;
}


/*********************************************/
.top-section {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 10px;
}

.section {
  background-color: var(--cover-color);
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 10px 5px 5px rgb(182, 182, 182);
  color: var(--text-color);
}

.image {
  width: 100%;
  max-width: 250px;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.about-container {
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
}

.title-1 {
  font-size: 22px;
}

.title-2 {
  font-size: 16px;
}

.text {
  white-space: normal;
  line-height: 1.6;
  font-size: 18px;
  white-space: pre-line;
  text-align: justify;
  text-justify: inter-word;
}

.job-skills button {
  background-color: var(--text-color);
  color: var(--cover-color);
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 10px;
  border: none;
  margin-top: 10px;
  font-size: 16px;
}

.skills {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 10px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skill-icon {
  font-size: 54px;
}

.skill-text {
  padding: 5px 0;
}

.contact-input {
  width: 80%;
  padding-left: 10px;
  font-size: 16px;
  color: black;
  background-color: white;
}

.submit-input {
  border-radius: 12px;
  padding: 10px 40px;
  background-color: var(--text-color);
  color: var(--cover-color);
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s;
}

.submit-input:hover {
  transform: scale(1.1);
}

.connect-header {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.error-msg {
  margin: 0;
  color: red;
  margin-top: -7px;
  margin-bottom: 6px;
  visibility: visible;
}

.error-msg-2 {
  margin: 0;
  margin-top: -26px;
  margin-bottom: 10px;
  visibility: visible;
}

.connect-icon {
  width: 100%;
  max-width: 250px;
  display: block;
  margin: 0 auto;
}

.connect-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social-icons {
  text-align: right;
}

.social-icon {
  font-size: 32px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  display: inline-block;
  margin: 0 10px;
}

.social-icon:hover {
  transform: scale(1.1);
}

/*********************************************/
@media (max-width: 600px) {
  .content {
    margin-top: -10px;
  }

  .text {
    font-size: 16px;
  }

  .job-skills button {
    font-size: 14px;
  }
}

.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}